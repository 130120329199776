import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import { BsChevronDown } from '@react-icons/all-files/bs/BsChevronDown'

import Seo from '../components/seo'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
import ProductCarousel from '../components/ProductCarousel'
import {
  space,
  Title,
  Subtitle,
  fontSizes,
  Container,
  breakpoints,
  MainContent,
  Spacer,
} from '../utils/styles'

const Wrapper = styled.div`
  margin-top: 100vh;
`

const Caption = styled.div`
  color: ${({ color }) => (color ? color : 'black')};
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: flex-start;
  text-align: center;
  animation: fadeInFromBottom 1s;
  overflow-x: hidden;
`

const ScrollDownIconWrapper = styled.span`
  width: 100%;
  position: absolute;
  bottom: 1.45rem;
  display: flex;
  justify-content: center;
`

const PhotoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: ${({ gap }) => (gap ? gap : space[5])};

  @media (max-width: ${breakpoints.m}px) {
    display: block;

    & > * {
      margin-bottom: ${({ gap }) => (gap ? gap : space[6])};
    }

    & > :last-child {
      margin-bottom: 0rem;
    }
  }
`

const GridTitleWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
`

const GridTitle = styled.h3`
  color: ${({ color }) => (color ? color : 'white')};
  font-size: ${fontSizes['4xl']};
  font-weight: 500;
  margin: ${space[4]};
  padding: 0;
  text-align: center;
  z-index: 2;

  @media (max-width: ${breakpoints.s}px) {
    font-size: ${fontSizes['2xl']};
  }
`

const GridItem = styled.div`
  grid-area: ${({ gridArea }) => (gridArea ? gridArea : null)};
  position: relative;
  height: 100%;
  width: 100%;
`

const Index = ({ data: { frontpageProducts } }) => {
  const { t } = useTranslation()

  return (
    <>
      <Seo
        title={t('Home')}
        keywords={[`nighttec`, `nightvision`, `defence`]}
      />
      <Navigation color="black" />
      <Caption color="white">
        <Container
          style={{
            textAlign: 'left',
            width: '100%',
          }}
        >
          <Title>
            <Trans>Your Specialist</Trans>
          </Title>
          <Subtitle>
            <Trans>In Nightvision</Trans>
          </Subtitle>
        </Container>
        <ScrollDownIconWrapper>
          <a href="#main">
            <BsChevronDown color="white" size={48} />
          </a>
        </ScrollDownIconWrapper>
      </Caption>
      <StaticImage
        src="../images/Home2.png"
        layout="fullWidth"
        alt="NightTec night vision googles"
        style={{
          maxWidth: '100vw',
          width: '100vw',
          height: '100vh',
          objectFit: 'cover',
          position: 'absolute',
          zIndex: 1,
          top: 0,
        }}
      />

      <Wrapper>
        <Container>
          <MainContent id="main">
            <PhotoGrid>
              <GridItem gridArea="1 / 1 / 2 / 5">
                <Link to="/shop">
                  <GridTitleWrapper>
                    <GridTitle>Shop</GridTitle>
                  </GridTitleWrapper>
                  <StaticImage
                    src="../images/Shop_n.jpg"
                    layout="fullWidth"
                    alt="Shop"
                    loading="lazy"
                    style={{ height: '100%' }}
                  />
                </Link>
              </GridItem>

              <GridItem gridArea="1 / 5 / 2 / 9">
                <Link to="/media">
                  <GridTitleWrapper>
                    <GridTitle>
                      <Trans>Media</Trans>
                    </GridTitle>
                  </GridTitleWrapper>
                  <StaticImage
                    src="../images/Media_n.jpg"
                    layout="fullWidth"
                    alt="Media"
                    loading="lazy"
                    style={{ height: '100%' }}
                  />
                </Link>
              </GridItem>

              <GridItem gridArea="1 / 9 / 3 / 13">
                <Link to="/rental">
                  <GridTitleWrapper>
                    <GridTitle>
                      <Trans>Rental</Trans>
                    </GridTitle>
                  </GridTitleWrapper>
                  <StaticImage
                    src="../images/Rental.jpg"
                    layout="fullWidth"
                    alt="Rental"
                    loading="lazy"
                    style={{ height: '100%' }}
                  />
                </Link>
              </GridItem>

              <GridItem gridArea="2 / 1 / 3 / 9">
                <Link to="/events">
                  <GridTitleWrapper>
                    <GridTitle>
                      <Trans>Events</Trans>
                    </GridTitle>
                  </GridTitleWrapper>
                  <StaticImage
                    src="../images/Events_n.jpg"
                    layout="fullWidth"
                    alt="Events"
                    loading="lazy"
                    style={{ height: '100%' }}
                  />
                </Link>
              </GridItem>
            </PhotoGrid>

            <Spacer height="5rem" />
            <ProductCarousel products={frontpageProducts.edges} />
            <Spacer height="5rem" />
          </MainContent>
        </Container>
        <Footer />
      </Wrapper>
    </>
  )
}

export const query = graphql`
  query ($language: String!) {
    frontpageProducts: allShopifyProduct(
      filter: { collections: { elemMatch: { title: { eq: "Frontpage" } } } }
    ) {
      edges {
        node {
          id
          title
          handle
          priceRangeV2 {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          featuredImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                  layout: CONSTRAINED
                  aspectRatio: 1
                  height: 300
                )
              }
            }
          }
        }
      }
    }
    i18nLocales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Index
