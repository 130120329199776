import styled from '@emotion/styled'
import { colors } from '../../utils/styles'

export const SlideContainer = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
`

export const Title = styled.h4`
  font-weight: 400;
  color: black;
`

export const Price = styled.h5`
  font-weight: 400;
  color: ${colors.androidGreen};
`
