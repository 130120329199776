import React, { useContext } from 'react'
import { Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby-plugin-react-i18next'

import { Price, SlideContainer, Title } from './styles'
import { useWindowDimensions } from '../../utils/hooks'
import { breakpoints } from '../../utils/styles'
import StoreContext from '../../context/StoreContext'
import { calcuateGrossPrice } from '../../utils/helpers'

const ProductCarousel = ({ products }) => {
  const { countryCode } = useContext(StoreContext)
  const { width } = useWindowDimensions()
  const properties = {
    duration: 4000,
    slidesToShow: width > breakpoints.m ? 4 : 2,
    slidesToScroll: 4,
    autoplay: true,
    indicators: false,
    easing: 'ease',
  }

  const i18nPrice = (price, currencyCode) =>
    Intl.NumberFormat(undefined, {
      currency: currencyCode ? currencyCode : 'EUR',
      minimumFractionDigits: 2,
      style: 'currency',
    }).format(parseFloat(price ? price : 0))

  const priceString = priceRangeV2 => {
    const { minVariantPrice, maxVariantPrice } = priceRangeV2

    if (maxVariantPrice.amount === minVariantPrice.amount) {
      return `${i18nPrice(
        calcuateGrossPrice(maxVariantPrice.amount, countryCode),
        maxVariantPrice.currencyCode
      )}`
    }
    return `${i18nPrice(
      calcuateGrossPrice(minVariantPrice.amount, countryCode),
      minVariantPrice.currencyCode
    )} - ${i18nPrice(
      calcuateGrossPrice(maxVariantPrice.amount, countryCode),
      maxVariantPrice.currencyCode
    )}`
  }

  return (
    <SlideContainer>
      <Slide {...properties} easing="ease">
        {products.map(
          ({
            node: { id, title, priceRangeV2, featuredImage, handle, variants },
          }) => (
            <Link
              key={id}
              to={`/shop/${handle}/`}
              style={{ textDecoration: 'none' }}
            >
              <GatsbyImage
                alt={title}
                image={featuredImage.localFile.childImageSharp.gatsbyImageData}
              />
              <Title>{title}</Title>
              <Price>{priceString(priceRangeV2)}</Price>
            </Link>
          )
        )}
      </Slide>
    </SlideContainer>
  )
}

export default ProductCarousel
